import {defineStore} from 'pinia'
import useUserStore from './modules/user'
import useViewStore from './modules/view'
import useShopStore from './modules/shop'
const useStore = defineStore('store',{
    state:() => ({
        user:useUserStore(),
        view:useViewStore(),
        shop:useShopStore()
    })
})

export default useStore