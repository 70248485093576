import {defineStore} from 'pinia'

const useViewStore = defineStore('view',{
    state:() => {

    },
    getters:{

    },
    actions:{
        
    }
}) 

export default useViewStore