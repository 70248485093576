<template>
    <router-view
	 v-if="initSuccessed"
	 v-slot="{ Component }">
    	<keep-alive>
    	  <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
    	</keep-alive>
    	<component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view>
</template>

<script setup>
import 'swiper/css';
import {useRoute,useRouter} from 'vue-router'
import {onMounted, ref, watch} from 'vue'
import {setToken,removeToken} from '@/libs/util'
import useStore from '@/store'

const router = useRouter()
const route = useRoute()
const initSuccessed = ref(false)
const debounce = (fn, delay) => {
	let timer = null;

	return function () {
		let context = this;

		let args = arguments;

		clearTimeout(timer);

		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
};

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
}; 

onMounted(async ()=>{
	const {shop} = useStore()
	await router.isReady()
	initSuccessed.value = true
	const wxapp_id = route.query.wxapp_id
	const shop_id = route.query.shop_id

    const token = route.query.token
    shop_id && shop.setShop_id(shop_id)
    wxapp_id && shop.setWxapp_id(wxapp_id)
    token?setToken(token):removeToken()
})
</script>

<style>
@import 'swiper/css';
/* 山河集 */
@import '//at.alicdn.com/t/c/font_1161655_cmzmrqeyp1c.css';
#app {
 height: 100%;
 width: 100%;
 font-size: 0.12rem;
}

body {

 /*适配底部黑条*/
  padding-bottom:constant(safe-area-inset-bottom);
  padding-bottom:env(safe-area-inset-bottom, 20px)
}
</style>
