import {createRouter, createWebHashHistory} from 'vue-router'
import {setToken,removeToken} from '@/libs/util'
import wx from 'weixin-js-sdk'
import useStore from "@/store";
const routes = [
    {
        path:'/',
        name:'山河集3D定制',
        component:()=>import('@/pages/homepage'),
        meta:{
            title : '山河集3D定制',
            requiresAuth:false
        }
    },
    {
        path:'/cart',
        name:'购物车',
        component:() => import('@/pages/cart'),
        meta:{
            title : '购物车',
            requiresAuth:true
        }
    }
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})

// const checkUserLoginStatus = (to) => {
// }
router.beforeEach((to, from, next) => {
    // 设置页面标题
    const pageTitle = to.meta?.title || '山河集3D定制';
    document.title = pageTitle;
  
    // 检查登录状态
    // const isAuthenticated = checkUserLoginStatus(to);
    if (to.meta.requiresAuth) {
        const {token} = to.query
        const {user} = useStore()
        token?next(): wx.miniProgram.redirectTo({
            url: `/pages/login/login?templateKey=token_${user.templateKey}&back=true`
          })
    } else {
      next(); // 继续导航
    }
  });


export default router