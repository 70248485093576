import Cookies from "js-cookie"
export const TOKEN_KEY = 'token'
var chars = ['0','1','2','3','4','5','6','7','8','9',
'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'
];
import axios from "axios";
function fetchGet(url, param) {
	return new Promise((resolve, reject) => {
		let requestData = param ? param : {};
		let data = requestData.data ? requestData.data : '';
		axios({
				method: 'get',
				url: url,
				params: data,
				headers: requestData.headers
			})
			.then(
				response => {
					if(response.data.code == 10402 && process.env.NODE_ENV !== 'development'){
						let timer = setTimeout(() => {
							  clearTimeout(timer);
							  if (
							    navigator.userAgent.indexOf('Firefox') != -1 ||
							    navigator.userAgent.indexOf('Chrome') != -1
							  ) {
							    window.location.href = 'about:blank';
							    window.close();
							  } else {
							    window.opener = null;
							    window.open('', '_self');
							    window.close();
							  }
							}, 1000);
							
					}else {
						resolve(response);
					}
				},
				err => {
					reject(err);
				}
			)
			.catch(error => {
				reject(error);
			});
	});
}

export const setToken = token => {
    Cookies.set(TOKEN_KEY,token,{
        expires:7
    })
}
export const getToken = () =>{
    const token = Cookies.get(TOKEN_KEY)
    if (token) return token
    else return false
}
export const removeToken = () => {
	Cookies.remove(TOKEN_KEY)
  return true
}
export const base64ToBlob = (data) => {
    var arr = data.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export const getRandomStr = (len) => {
    var res = "";
    for(var i = 0; i < len ; i ++) {
        var id = Math.ceil(Math.random()*61);
        res += chars[id];
    }
    return res;
} 
export const removeStyles = (html) => {
    // 定义要移除的标签及其属性
    var tags = ['style', 'script'];
    
    for (var i = 0; i < tags.length; i++) {
        html = html.replace(/<(\/?)([a-zA-Z]+)[^>]*>/g, function($0, $1, $2){
            if ($2 === tags[i]){
                return '';
            } else {
                return $0;
            }
        });
        
        // 去除内联样式
        html = html.replace(/ style=("[^"]*"|'[^']*')?/gi,'');
    }
    
    return html;
}