import {defineStore} from 'pinia'

const useShopStore = defineStore('shop',{
    state:() => ({
        wxapp_id:0,
        shop_id:0
    }),
    getters:{
        getWxapp_id(state){
            return state.wxapp_id
        },
        getShop_id(state){
            return state.shop_id
        }
    }, 
    actions:{
        setWxapp_id(wxapp_id){
            this.wxapp_id = wxapp_id
        },
        setShop_id(shop_id){
            this.shop_id = shop_id
        }
    },
    persist:true
}) 

export default useShopStore