import {defineStore} from 'pinia'

const useUserStore = defineStore('user',{
    state:() => ({
        templateKey:new Date().getTime()
    }),
    getters:{
       
    },
    actions:{
        
    },
    persist:true
})

export default useUserStore